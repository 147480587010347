import { createTheme } from '@mui/material/styles'

export class Colors {
  static colorD0DF00 = '#D0DF00'
  static color0D0D0E = '#0D0D0E'
  static color121213 = '#121213'
  static color23221F = '#23221F'
  static color373737 = '#373737'
  static color5A5957 = '#5A5957'
  static color91908F = '#91908F'
  static colorD7D9DB = '#D7D9DB'
  static colorF5F5F6 = '#F5F5F6'
  static colorCE492C = '#CE492C'
  static color1A1A18 = '#1A1A18'
  static colorC2D15B = '#C2D15B'
  static color7E7E80 = '#7E7E80'
  static color2F2F31 = '#2F2F31'
  static color272727 = '#272727'
  static color8c8c8c = '#8c8c8c'
  static colorFFEB3B = '#FFEB3B'
  static colorAAA8A1 = '#AAA8A1'
  static colorD9D9D9 = '#D9D9D9'

  // alias
  static yellow = Colors.colorD0DF00
  static red = Colors.colorCE492C
}

export function getColorWithAlpha(color: string, alpha: number) {
  // カラーコードをRGBに分割
  const hexCode = color.replace('#', '')
  const r = parseInt(hexCode.substring(0, 2), 16)
  const g = parseInt(hexCode.substring(2, 4), 16)
  const b = parseInt(hexCode.substring(4, 6), 16)

  alpha = Math.min(1, Math.max(0, alpha))
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const theme = createTheme({
  typography: {
    fontFamily: ['"M PLUS 1p"', 'sans-serif'].join(','),
    h1: {
      fontSize: 18,
      fontWeight: 500,
    },
    h2: {
      fontSize: 16,
      fontWeight: 500,
    },
    h3: {
      fontSize: 14,
      fontWeight: 400,
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
    },
    body2: {
      fontSize: 13,
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: Colors.yellow,
    },
    secondary: {
      main: Colors.color0D0D0E,
    },
    text: {
      primary: Colors.colorD7D9DB,
      secondary: Colors.color5A5957,
    },
    error: {
      main: Colors.red,
    },
    background: {
      default: Colors.color121213,
      paper: Colors.color0D0D0E,
    },
    divider: Colors.color23221F,
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: '46px',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          minHeight: '46px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: Colors.color91908F,
            backgroundColor: Colors.color5A5957,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: Colors.color5A5957,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: Colors.color91908F,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: Colors.color5A5957,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          paddingTop: '4px',
          paddingBottom: '4px',
          backgroundColor: Colors.color1A1A18,
          color: Colors.color5A5957,
          borderRadius: 7,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          color: Colors.color5A5957,
          background: Colors.color1A1A18,
          '&.Mui-selected': {
            backgroundColor: Colors.color272727,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: Colors.colorD7D9DB,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: Colors.color5A5957,
        },
      },
    },
  },
})

export default theme
