import i18n from 'i18next'
import { useEffect, useState } from 'react'
import { initReactI18next } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { myLanguageState } from '../store'
import { useSetMyLanguage } from './useMyLanguage'

export function useI18nSetup(): boolean {
  useSetMyLanguage()
  const [isSetupCompleted, setIsSetupCompleted] = useState(false)
  const myLanguage = useRecoilValue(myLanguageState)

  useEffect(() => {
    ;(async () => {
      if (myLanguage === undefined) return
      const json = await import(`@public/messages/message_${myLanguage}.json`)

      // eslint-disable-next-line import/no-named-as-default-member
      i18n
        .use(initReactI18next)
        .init({
          lng: myLanguage,
          resources: {
            [myLanguage]: {
              translation: json,
            },
          },
          react: {
            transKeepBasicHtmlNodesFor: ['span', 'br'],
          },
        })
        .then(() => setIsSetupCompleted(true))
    })()
  }, [myLanguage])

  return isSetupCompleted
}
