import { useMediaQuery as useMuiMediaQuery, useTheme } from '@mui/material'
import { useMemo } from 'react'

interface MediaQuery {
  /** smサイズ(600px以上)であるか */
  isSm: boolean
}

export function useMediaQuery(): MediaQuery {
  const theme = useTheme()
  const isSm = useMuiMediaQuery(theme.breakpoints.up('sm'))

  return useMemo(
    () => ({
      isSm,
    }),
    [isSm],
  )
}
