import { Language } from '~/types/api/users'

const LANGUAGE_LOCAL_STORAGE_KEY = 'language'

export const setLanguageLocalStorage = (language: Language) => {
  window.localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, language)
}

export const getLanguageLocalStorage = (): string | undefined => {
  const value = window.localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY)

  if (value === null) {
    return undefined
  }

  return value
}
