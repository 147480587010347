import { SvgIcon } from '@mui/material'
import { type SvgIconProps } from '@mui/material/SvgIcon'
import { forwardRef } from 'react'
import { getComponent, getViewBox } from './component'
import { SvgAssetType } from './type'

export const SvgAsset = forwardRef<typeof SvgIcon, SvgIconProps & { asset: SvgAssetType }>(
  ({ asset, ...props }, _ref) => {
    return <SvgIcon {...props} viewBox={getViewBox(asset)} component={getComponent(asset)} />
  },
)

SvgAsset.displayName = 'SvgAsset'
