import { atom, selector } from 'recoil'

export const accessTokenState = atom<string | undefined>({
  key: 'access_token',
  default: undefined,
})

// ユーザーのアカウントの状態を表す.
// 以下の状態に基づいて表示画面や呼び出すAPIを出し分ける.
//
// loading: 読み込み中
// notSignedIn: 非ログイン状態
// signedIn: 有効なアカウントでログイン中
// noProfile: CognitoにはログインしているがSamansaのアカウントが未作成
// conflict: ログイン中だが同メアドの他ログイン方法でログインしないとダメ
// preventNewSocialSignedIn: ログイン方法でSNSを使い新規登録したユーザ (SNSログイン制御前からSNSログインを使っていた人は対象外)
export type UserLoginState =
  | 'loading'
  | 'notSignedIn'
  | 'signedIn'
  | 'noProfile'
  | 'conflict'
  | 'preventNewSocialSignedIn'

export const userLoginState = atom<UserLoginState>({
  key: 'user_login_state',
  default: 'loading',
})

// アクセストークンを持ち、有効なアカウントでサインイン済みかどうか
// つまり認証が必要なエンドポイントを叩けるかどうかを返す
export const availableAuthApiState = selector({
  key: 'has_token_state',
  get: ({ get }) => {
    return get(accessTokenState) !== undefined && get(userLoginState) == 'signedIn'
  },
})

export const loginInfoState = atom<{ email: string; provider: string }>({
  key: 'login_info',
  default: { email: '', provider: '' },
})
