import { useRecoilValue } from 'recoil'
import { myLanguageState } from '../store'

/**
 * url?locale=<my_language>の形でクエリパラムに付与するための値を作成するhooks
 */
export function useAddLocaleParams(): string {
  const language = useRecoilValue(myLanguageState)

  return `locale=${language}`
}
