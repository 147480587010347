import { ReactNode } from 'react'
import { LoadingBackdrop } from '~/components/LoadingBackdrop'
import { useI18nSetup } from '../hooks/useI18nSetup'

interface AppLanguagePreparationProps {
  children: ReactNode
}

export function AppLanguagePreparation({ children }: AppLanguagePreparationProps): JSX.Element {
  const isSetupCompleted = useI18nSetup()

  if (!isSetupCompleted) {
    return <LoadingBackdrop />
  }

  return <>{children}</>
}
