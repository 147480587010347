import { useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import { myLanguageState } from '~/features/i18n/store'
import { getBrowserLanguage } from '~/features/i18n/utils/getBrowserLanguage'
import { isSupportedLanguage } from '~/features/i18n/utils/isSupportedLanguage'
import {
  getLanguageLocalStorage,
  setLanguageLocalStorage,
} from '~/features/i18n/utils/languageLocalStorage'
import { useAxios } from '~/libs/axios'
import { availableAuthApiState } from '~/recoil/auth'
import { Language, Profile } from '~/types/api/users'
import { useAddLocaleParams } from './useAddLocaleParams'

export function useSetMyLanguage() {
  const axios = useAxios()
  const firstRef = useRef(true)
  const localeParams = useAddLocaleParams()
  const availableAuthApi = useRecoilValue(availableAuthApiState)
  const { data: profile, isLoading } = useSWR<Profile>(
    availableAuthApi ? `/v2/user/edit?${localeParams}` : null,
    (args) => axios.get(args).then((res) => res.data),
  )
  const setMyLanguageState = useSetRecoilState(myLanguageState)

  useEffect(() => {
    if (isLoading) return
    const myLanguage = profile !== undefined ? profile.language : null
    const browserLanguage = getBrowserLanguage()
    const localStorageLanguage = getLanguageLocalStorage()
    let selectedLanguage: Language = 'en'

    /**
     * 言語の設定優先順位
     * ① ユーザプロフィールに紐づく言語 (ユーザが設定した言語)
     * ② 設定変更した際に保存したローカルストレージの値 (language)
     * ③ ブラウザのデフォルト言語
     * ④ 英語
     */
    if (myLanguage !== null) {
      selectedLanguage = myLanguage
    } else if (localStorageLanguage && isSupportedLanguage(localStorageLanguage)) {
      selectedLanguage = localStorageLanguage as Language
    } else if (isSupportedLanguage(browserLanguage)) {
      selectedLanguage = browserLanguage as Language
    }
    setMyLanguageState(selectedLanguage)

    if (selectedLanguage !== localStorageLanguage) {
      setLanguageLocalStorage(selectedLanguage)
    }
  }, [isLoading, profile, setMyLanguageState])
}
