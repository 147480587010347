import { Backdrop, CircularProgress } from '@mui/material'

interface LoadingBackdropProps {
  open?: boolean
}

export function LoadingBackdrop({ open = true }: LoadingBackdropProps): JSX.Element {
  return (
    <Backdrop open={open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}
